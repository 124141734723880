import axios from 'axios'
import { NextPage, GetServerSideProps } from 'next'
import { BreadcrumbJsonLd } from 'next-seo'
import { MetaHead } from '../components/MetaHead'
import { IEvent, IGallery, INews, IServerProps, ISiteExport } from '../@types'
import { WEB_URL } from '../config'

const Home: NextPage<ISiteExport> = ({
  meta,
  HomePage,
  eventList,
  newsList,
  galleryList,
}) => {
  return (
    <div>
      {/* pass meta content from here */}
      <MetaHead
        meta={{
          ...meta,
          title:
            'Royal College Old Boys’ Association Western Australia | RCOBAWA',
          description:
            'RCOBAWA is an alumni organisation in the state of WA, Australia that is dedicated to Old Boys of the beloved Alma Mater, Royal College. Incorporated in Perth - WA, membership is open to all past students of Royal College (RC) Colombo, Sri Lanka and the Junior School formerly known as Royal Primary School (RPS).',
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: 'Home',
            item: WEB_URL,
          }
        ]}
      />
      <HomePage
        eventList={eventList}
        newsList={newsList}
        galleryList={galleryList}
      />
    </div>
  )
}

export const getServerSideProps: GetServerSideProps = async () => {
  const props: IServerProps = {
    modules: ['theme', 'meta', 'HomePage'],
  }
  try {
    const [events, news, albums] = await Promise.all([
      axios.get('/items/upComping'),
      axios.get(`/news?limit=4&offset=1&orderBy=DESC&active=true`),
      axios.get(`/albums?limit=6&offset=1&orderBy=DESC&active=true`),
    ])
    const eventList: Array<IEvent> = events.data
    props.eventList = eventList
    const newsList: Array<INews> = news.data.docs
    props.newsList = newsList
    const galleryList: Array<IGallery> = albums.data.docs
    props.galleryList = galleryList
  } catch (error) {
    console.error(error)
  }
  return {
    props,
  }
}

export default Home
